/**
 * File library.js
 * 
 * Custom scripts.
 */

jQuery(document).ready(function($) {
	$(window).scrollTop(0);
  $(document).scrollTop(0);
  window.scrollTo(0, 0)
  $(window).on('beforeunload', function() {
    $(window).scrollTop(0);
  });

	// Registering GSAP plugin
	gsap.registerPlugin(ScrollTrigger);
	let mm = gsap.matchMedia()

	// Global Variables
	var headerHeight = $('#masthead').outerHeight(),
		menuButton = $('.menu-button');

	// Smooth anchor scroll
	$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
		if (this.hash !== "") {
			e.preventDefault();

			var hash = this.hash;
			$('html, body').animate({
				scrollTop: $(hash).offset().top - headerHeight
			}, 1000);
		}
	});

	// Adjust margin for page without header images
	if ( $('body').hasClass('no-header-images') ) {
		$('#content').css( 'padding-top', headerHeight );
	}

	// Menu
	var menu = gsap.timeline({
		paused: true,
		onStart: function() {
			$('body').addClass( 'open-menu' );
			$('#menu-offcanvas').addClass( 'on' );
		},
		onReverseComplete: function() {
			$('body').removeClass( 'open-menu' );
			$('#menu-offcanvas').removeClass( 'on' );
			
			submenus.forEach((submenu, index) => {
				tween[index].reverse();
			});
		}
	});

	menu.to(
		'.menu-item',
		{
			duration: 0.25,
			opacity: 1,
			ease: 'Power2.inOut',
			stagger: 0.05
		}
	).to(
		'.menu-bottom',
		{
			opacity: 1,
			ease: 'Power2.inOut'
		}
	);

	// Sub items menu
	var submenus = gsap.utils.toArray('.sub-menu'),
		tween = [];

	submenus.forEach((submenu, index) => {
		tween[index] = gsap.from(
			submenu,
			{
				duration: 0.25,
				height: 0,
				paused: true,
				reversed: true,
				ease: 'Power2.inOut'
			}
		);
	});

	mm.add(
		{
			isDesktop: `(min-width: 1024.001px)`,
			isTablet: `(min-width: 768.001px)`,
		},
		(context) => {
			let { isDesktop, isTablet } = context.conditions;
			
			if ( isDesktop ) {
				// Header hide/show on scroll
				var header = gsap.timeline({
					paused: true
				})
					.from(
						'#masthead',
						{
							yPercent: -100,
							duration: 0.2
						}
					)
					.fromTo(
						'#site-tools',
						{
							yPercent: 0,
							bottom: 40,
						},
						{
							yPercent: 100,
							bottom: 0,
							duration: 0.2
						},
						'<'
					)
					.progress(1)
		
				ScrollTrigger.create({
					start: 'top top',
					end: 'top+=1px top',
					// markers: true,
					toggleClass: {
						targets: '#masthead',
						className: 'upward'
					},
					onUpdate: (self) => {
						if ( !$('body').hasClass('open-menu') ) self.progress === 0 ? header.play() : header.reverse()
					}
				});
		
				// Gallery revealed
				var gallery = $('#gallery'),
					galleryItem = $('.gallery-grid');
		
				if ( gallery.length > 0 ) {
					gsap.to(
						galleryItem,
						{
							scale: 0.4,
							ease: 'none',
							scrollTrigger: {
								trigger: galleryItem,
								start: 'top top',
								scrub: 0.8,
								pin: true
							}
						}
					);
				}
			}
		
			if ( isTablet ) {
				// Backgrounds
				var sections = gsap.utils.toArray('.section');
		
				if ( sections.length > 0 ) {
					sections.forEach((section) => {
						if ( section.getAttribute('data-bgcolor' ) !== null) {
							var bgcolor = section.getAttribute('data-bgcolor');
		
							gsap.to(
								'#content',
								{
									backgroundColor: bgcolor,
									immediateRender: false,
									scrollTrigger: {
										trigger: section,
										start: 'top 85%',
										end: '+=60%',
										scrub: true,
										toggleActions: 'restart pause reverse pause',
										// markers: true,
									}
								}
							);
						}
					});
				}
		
				// Words animation
				// Provare: https://github.com/lukePeavey/SplitType
				var words = gsap.utils.toArray('.words-animation span');
		
				if ( words.length > 0 ) {
					words.forEach((word, index) => {
						gsap.from(
							word,
							{
								duration: 0.5,
								yPercent: 120,
								scrollTrigger: {
									trigger: word,
									start: 'top bottom'
								}
							}
						);
					});
				}
		
				// Titles (sliding up)
				var titles = gsap.utils.toArray('.slide');
		
				if ( titles.length > 0 ) {
					titles.forEach((title) => {
						var delay = title.getAttribute('data-delay'),
							scroll = title.getAttribute('data-scroll'),
							start = 'top center+=100%';
		
						if ( scroll == 'no' ) start = 'top bottom';
		
						title.parentNode.style.overflow = 'hidden';
		
						gsap.from(
							title,
							{
								delay: delay,
								duration: 1.5,
								yPercent: 120,
								scrollTrigger: {
									trigger: title,
									start: start
								}
							}
						);
					});
				}
		
				// Texts (fading up)
				var texts = gsap.utils.toArray('.fade');
		
				if ( texts.length > 0 ) {
					texts.forEach((text) => {
						var delay = text.getAttribute('data-delay'),
							scroll = text.getAttribute('data-scroll'),
							start = 'top center+=100%';
		
						if ( scroll == 'no' ) start = 'top bottom';
		
						gsap.from(
							text,
							{
								delay: delay,
								duration: 1,
								autoAlpha: 0,
								y: 50,
								scrollTrigger: {
									trigger: text,
									start: start
								}
							}
						);
					});
				}
		
				// Images reveal
				var containers = gsap.utils.toArray('.reveal');
		
				if ( containers.length > 0 ) {
					containers.forEach((container) => {
						var image = container.querySelector('img'),
							tn = gsap.timeline({
								scrollTrigger: {
									trigger: container,
									start: 'top center+=50%'
								}
							});
						
						tn.set(
							container,
							{
								autoAlpha: 1
							}
						);
		
						if ( container.classList.contains('right-side') ) {
							tn.from(
								container,
								2,
								{
									xPercent: 100,
									ease: 'Power2.easeOut'
								}
							);
		
							tn.from(
								image,
								2,
								{
									xPercent: -100,
									scale: 1.3,
									delay: -2,
									ease: 'Power2.easeOut'
								}
							);
						} else {
							tn.from(
								container,
								2,
								{
									xPercent: -100,
									ease: 'Power2.easeOut'
								}
							);
		
							tn.from(
								image,
								2,
								{
									xPercent: 100,
									scale: 1.3,
									delay: -2,
									ease: 'Power2.easeOut'
								}
							);
						}
					});
				}
			}
		}
	);


	// Activate/Deactivate sub-menus
	$('#primary-menu').find('a[href="#"]').each(function(i) {
		$(this).on('click', function(e) {
			e.preventDefault();

			tween[i].reversed() ? tween[i].play() : tween[i].reverse();
		});
	});

	// Hide/show menu off canvas
	menuButton.on('click', function(e) {
		e.preventDefault();
		
		$(this).toggleClass('collapsed');

		if ( ! $(this).hasClass('collapsed') ) {
			$('#primary-menu .menu-item > a').next('.sub-menu').removeClass('open');
			menu.play();
		} else {
			menu.reverse();
		}
	});

	$( window ).on( 'load', function() {
		ScrollTrigger.refresh();
	});

	let timer_id;
	$( window ).on( 'resize', function() {
		ScrollTrigger.refresh();

		clearTimeout(timer_id);
		timer_id = setTimeout(function() {

			if ( $(window).width() >= 1024 ) {
				// Adjust margin for page without header images
		
				if ( $('body').hasClass('no-header-images') ) {
					$('#content').css( 'padding-top', headerHeight );
				}
			}
			
		}, 300);

	});

});

// Magic mouse
if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

/* Swiper - Begin */

// Values slideshow
const valuesSlider = new Swiper('#mission .values-slideshow', {
	slidesPerView: 1,
	speed: 1000,
	pagination: {
		el: '#mission .swiper-pagination',
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + (index + 1) + '</span>';
		},
	},
	breakpoints: {
		768: {
			slidesPerView: 1.55,
			spaceBetween: 40
		}
	},
});

// Text sliding
const seatsTextSlider = new Swiper('#seats .sliding-text', {
	loop: true,
	slidesPerView: 1.25,
	allowTouchMove: false,
	speed: 10000,
	autoplay: {
		delay: 5,
		disableOnInteraction: false,
	},
	breakpoints: {
		768: {
			slidesPerView: 'auto',
			centeredSlides: true,
		}
	},
});

const blogTextSlider = new Swiper('#blog .sliding-text', {
	loop: true,
	slidesPerView: 2,
	allowTouchMove: false,
	speed: 10000,
	autoplay: {
		delay: 5,
		disableOnInteraction: false,
	},
	breakpoints: {
		768: {
			slidesPerView: 'auto',
			centeredSlides: true,
		}
	},
});

// // Reviews slideshow
// const reviewsSlider = new Swiper('#reviews .swiper', {
// 	speed: 1000,
// 	navigation: {
// 		nextEl: '#reviews .swiper-button-next',
// 		prevEl: '#reviews .swiper-button-prev',
// 	},
// });

//reviews gallery and animations
if (jQuery('#reviews').length) {
	let reviewsIndex = 0
	const reviewsSlides = jQuery('#reviews .hidden')

	jQuery('#review_prev').on('click', ()=>{
		reviewsIndex = reviewsIndex==0 ? reviewsSlides.length-1 : reviewsIndex-1

		gsap.to('#reviews .slide.main', {
			opacity: 0,
			duration: .3,
			onComplete: () => {
				jQuery('#reviews .slide.main').children('.review').html(
					jQuery(reviewsSlides[reviewsIndex]).children('.review').html()
				)

				jQuery('#reviews .slide.main').children('.author').text(
					jQuery(reviewsSlides[reviewsIndex]).children('.author').text()
				)

				gsap.to('#reviews .slide.main', {
					opacity: 1,
					duration: .3,
				})
			}
		})

		gsap.to('#reviews .slide.main', {
			height: jQuery(reviewsSlides[reviewsIndex]).innerHeight(),
			duration: .5,
			ease: 'none',
		})
	})

	jQuery('#review_next').on('click', ()=>{
		reviewsIndex = reviewsIndex==reviewsSlides.length-1 ? 0 : reviewsIndex+1

		gsap.to('#reviews .slide.main', {
			opacity: 0,
			duration: .3,
			onComplete: () => {
				jQuery('#reviews .slide.main').children('.review').html(
					jQuery(reviewsSlides[reviewsIndex]).children('.review').html()
				)

				jQuery('#reviews .slide.main').children('.author').text(
					jQuery(reviewsSlides[reviewsIndex]).children('.author').text()
				)

				gsap.to('#reviews .slide.main', {
					opacity: 1,
					duration: .3,
				})
			}
		})

		gsap.to('#reviews .slide.main', {
			height: jQuery(reviewsSlides[reviewsIndex]).innerHeight(),
			duration: .5,
			ease: 'none',
		})
	})

	jQuery('#reviews .slide.main').css('height', jQuery(reviewsSlides[reviewsIndex]).innerHeight())
	jQuery(window).on('resize', ()=>{
		jQuery('#reviews .slide.main').css('height', jQuery(reviewsSlides[reviewsIndex]).innerHeight())
	})
}

// Included gallery slideshow
const incGallerySlider = new Swiper('.gallery-slideshow', {
	loop: true,
	slidesPerView: 1,
	speed: 1000,
	breakpoints: {
		768: {
			slidesPerView: 3,
			slidesPerGroup: 1,
		}
	},
});

/* Swiper - End */

// Lightbox effects
const lightbox = GLightbox({
	loop: true,
	moreLength: 0
});

// Play/pause videos on hover
const videos = document.querySelectorAll('.video');

if (videos.length > 0) {
	videos.forEach((video) => {
		video.addEventListener('mouseover', function() {
			this.play();
		});

		video.addEventListener('mouseout', function() {
			this.pause();
			this.currentTime = 0;
		});
	});
}

// Parallax effect (home)
document.addEventListener("DOMContentLoaded", function() {
	// TEXT SLIDE
	if (document.documentElement.clientWidth > 767) {
		// Seleziona tutti gli elementi con la classe 'slide_small'
		let elements = document.querySelectorAll('.slide_small');

		// Imposta ogni elemento per essere trasparente e spostato verso il basso
		if (elements.length > 0) {
			elements.forEach((element) => {
				gsap.set(element, {autoAlpha: 0, y: 100});
			});

			// Crea un'animazione per ogni elemento
			let animations = Array.from(elements).map((element) => {
				return gsap.to(element, {autoAlpha: 1, y: 0, paused: true});
			});

			// Aggiungi un listener di evento per il scroll
			window.addEventListener('scroll', () => {
				// Controlla se ogni elemento è visibile
				elements.forEach((element, index) => {
					let rect = element.getBoundingClientRect();
					if (rect.top <= window.innerHeight && rect.bottom >= 0) {
						// Se l'elemento è visibile, riproduci la sua animazione
						animations[index].play();
					}
				});
			});
		}
	}

	// BLUR EFFECT
	if (document.documentElement.clientWidth > 767) {
		// Seleziona tutti gli elementi con la classe 'fade_small'
		let blurElements = gsap.utils.toArray('.fade_small');

		// Imposta ogni elemento per avere un effetto di sfocatura
		if (blurElements.length > 0) {
			blurElements.forEach((blurElement) => {
				gsap.set(blurElement, {opacity: .25, duration: 0.8 });
			});

			// Crea un'animazione per ogni elemento
			let blurAnimations = Array.from(blurElements).map((blurElement) => {
				return gsap.to(blurElement, {opacity: 1, paused: true, duration: 0.8 });
			});

			window.addEventListener('scroll', () => {
				// Controlla se ogni elemento è al centro dello schermo
				blurElements.forEach((blurElement, index) => {
				let rect = blurElement.getBoundingClientRect();
				if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
					// Se l'elemento è al centro dello schermo, rimuovi l'effetto di sfocatura
					blurAnimations[index].play();
				} else if (rect.bottom < window.innerHeight / 2 || rect.top > window.innerHeight / 2) {
					// Altrimenti, se l'elemento ha iniziato a lasciare il centro dello schermo, applica l'effetto di sfocatura
					blurAnimations[index].reverse();
				}
				});
			});
		}
	}

	// SCROLL TIMELINE
	if (document.documentElement.clientWidth > 767) {
		// Get the .time_line element
		const timeLine = document.querySelector(".time_line");
		
		if (timeLine) {
			// Get the .dot element
			const time_line_cover = document.querySelector(".time_line_cover");

			// Get the height of the .time_line element
			const timeLineHeight = timeLine.offsetHeight;
			
			// Create a GSAP animation that scrolls with the user
			const tl = gsap.timeline({
				scrollTrigger: {
					ease: 'linear',
					trigger: timeLine, // Trigger when the .time_line element enters the viewport
					start: "top bottom", // Start at the top of the .time_line element
					end: "bottom center", // End at the bottom of the .time_line element
					scrub: true // Smooth the animation based on the scroll position
				}
			});
			
			tl.to(time_line_cover, {
				height: timeLineHeight, // Change the height to the height of the .time_line element
				delay: 0.05,
				duration: 0.2,
				ease: 'linear'
			});
		}
	}

});

// ANIMAZIONE PER FORMAZIONE ED EXTRA
if (document.documentElement.clientWidth > 767) {
	const boxes = document.querySelectorAll(".image_container.even");
	const dx_boxes = document.querySelectorAll(".image_container.odd");

	// Animation for even boxes
	if (boxes.length > 0) {
		boxes.forEach((box, index) => {
			gsap.fromTo(box,
				{ yPercent: 60 }, // start from the bottom
				{
					yPercent: -50, // end position
					ease: "expo.out",
					scrollTrigger: {
						trigger: box,
						scrub: true
					},
				}
			);
		});
	}

	// Animation for odd boxes
	if (dx_boxes.length > 0) {
		dx_boxes.forEach((dx_box, index) => {
			gsap.fromTo(dx_box,
				{ yPercent: -30 }, // start from the bottom
				{
					yPercent: 10, // end position
					ease: "expo.inOut",
					scrollTrigger: {
						trigger: dx_box,
						scrub: true
					},
				}
			);
		});
	}
}
